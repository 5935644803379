<template>
  <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
  </div>
  <div v-else>
      <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1" v-if="client.name">{{client.name}}</h3>
          <h3 class="md-title" style="flex: 1" v-else>Client name</h3>
          
          <md-button class="md-primary md-raised" @click="fireUpdate">Update client</md-button>
      </md-toolbar>
      <div class="main-inner-wrapper">
        <CRow :gutters="true">
            <CCol sm="12" lg="5">
                <md-card>
                    <md-card-header>
                        <div class="md-title">Client info</div>
                    </md-card-header>

                    <md-card-content>
                        <CRow>
                            <CCol sm="12">
                                <md-switch v-model="client.type" class="md-primary" value="individual">Individual</md-switch>
                                <md-switch v-model="client.type" class="md-primary" value="corporate">Corporate</md-switch>
                            </CCol>
                        </CRow>
                        
                        <CRow>
                            <CCol sm="12">
                                <md-field :class="getValidationClass('client.email')">
                                    <label>{{client.type === 'individual' ? 'Email' : 'Company email'}}</label>
                                    <md-input v-model="client.email" @keyup="fireClientCheck()"></md-input>
                                    <md-progress-spinner class="mb-2" v-show="checkingExistantClient" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                    <span class="md-error" v-if="!$v.client.email.required">Email is required</span>
                                    <span class="md-error" v-else-if="!$v.client.email.email">Type a valid email</span>
                                    <span class="md-error" v-else-if="clientExsits">You have a client under this email <a @click="goToClient()">View Client</a> or change email</span>
                                    <span class="md-error" v-else-if="userCheck && (!isPassenger) && client.type === 'individual'">This user already has an account but not passenger role available . Ask for another mail</span>
                                    <span class="md-error" v-else-if="userCheck && (!isClient) && client.type === 'corporate'">This user already has an account but not client role available . Ask for another mail</span>
                                </md-field>
                                <md-field :class="getValidationClass('client.name')" v-if="client.type == 'corporate'">
                                    <label>Company name</label>
                                    <md-input v-model="client.name" :disabled="disabledField()"></md-input>
                                    <span class="md-error" v-if="!$v.client.name.required">Company name is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('client.firstName')" v-if="client.type === 'individual'">
                                    <label>Name</label>
                                    <md-input v-model="client.firstName" :disabled="disabledField()"></md-input>
                                    <span class="md-error" v-if="!$v.client.firstName.required">Name is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('client.lastName')" v-if="client.type === 'individual'">
                                    <label>Lastname</label>
                                    <md-input v-model="client.lastName" :disabled="disabledField()"></md-input>
                                    <span class="md-error" v-if="!$v.client.lastName.required">Lastname is required</span>
                                </md-field>
                                
                                <md-field :class="getValidationClass('client.phone')">
                                    <label>{{client.type === 'individual' ? 'Phone' : 'Company phone'}}</label>
                                    <md-input v-model="client.phone" :disabled="disabledField()"></md-input>
                                    <span class="md-error" v-if="!$v.client.phone.required">Name is required</span>
                                </md-field>
                                <md-field >
                                    <label>Additional Phone</label>
                                    <md-input v-model="client.additionalPhone"></md-input>
                                </md-field>
                            </CCol>
                        </CRow>
                        <CRow >
                              <CCol sm="12">
                                <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete client</md-button>
                            </CCol>
                        </CRow>
                    </md-card-content>
                
                </md-card>
            </CCol>
            <CCol sm="12" lg="7" >
                <md-card>
                    <md-card-header>
                        <div class="md-title">Passengers</div>
                    </md-card-header>

                    <md-card-content>
                        <CRow>
                            <CCol sm="12">
                                <md-empty-state
                                    md-icon="person_add"
                                    md-label="Add passengers"
                                    md-description="Create available corporation passengers."
                                    v-if="passengers.length == 0"
                                    >
                                    <md-button class="md-primary md-raised" @click="dialog.active = true">Add passenger</md-button>
                                </md-empty-state>
                                <md-table v-else>
                                    <md-table-row>
                                        <md-table-head>Name</md-table-head>
                                        <md-table-head>Lastname</md-table-head>
                                        <md-table-head>Email</md-table-head>
                                        <md-table-head>Phone</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row v-for="(item, index) in passengers" v-bind:key="'pass-' + index">
                                        <md-table-cell>
                                            {{ item.firstName ? item.firstName : '' }} 
                                            <span v-if="!item.firstName" class="up-text-soft-grey">Name</span>
                                        </md-table-cell>
                                        <md-table-cell class="white-space-no-wrap">
                                            <span>{{ item.lastName ? item.lastName : '' }} </span>
                                            <span v-if="!item.lastName" class="up-text-soft-grey">Lastname</span>
                                            <md-chip class="md-primary ml-2" v-if="item.default">Default</md-chip> 
                                        </md-table-cell>
                                        <md-table-cell>
                                            {{ item.email ? item.email : '' }} 
                                            <span v-if="!item.email" class="up-text-soft-grey">Email</span>
                                        </md-table-cell>
                                        <md-table-cell>
                                            {{ item.phone ? item.phone : '' }} 
                                            <span v-if="!item.phone" class="up-text-soft-grey">Phone</span>
                                        </md-table-cell>
                                        <md-table-cell class="white-space-no-wrap">
                                            <md-tooltip v-if="item.default" md-direction="top">Default passenger must be edited from client info</md-tooltip>
                                            <md-button class="md-icon-button" @click="removePassenger(index)" :disabled="item.default">
                                                <md-icon>delete</md-icon>
                                                
                                            </md-button>
                                            <md-button class="md-icon-button" @click="editPassenger(index)" :disabled="item.default">
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                                <md-button class="md-primary md-raised mt-4 full-width-button" @click="dialog.active = true" v-if="passengers.length > 0">Add passenger</md-button>
                            </CCol>
                        </CRow>
                    </md-card-content>
                </md-card>

            </CCol>
        </CRow>
      </div>
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

      <md-dialog :md-active.sync="dialog.active" :style="[dialogDimensions]" 
                     :md-fullscreen="false" class="free-dialog"
                     @md-closed="passengerDialogClosed()"
            >
          <md-dialog-title class="md-title">Passenger info</md-dialog-title>
          <div class="up-modal-content" >
              <CRow class="pb-3">
                  <CCol sm="12">
                      <md-field :class="getValidationClass('dialog.email')">
                          <label>Email</label>
                          <md-input v-model="dialog.passenger.email" ></md-input>
                          <md-progress-spinner class="mb-2" v-show="dialog.checkingUser" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                          <span class="md-error" v-if="!$v.dialog.passenger.email.required">Email is required</span>
                      </md-field>
                      <md-field :class="getValidationClass('dialog.name')" >
                          <label>Name</label>
                          <md-input v-model="dialog.passenger.firstName" ></md-input>
                          <span class="md-error" v-if="!$v.dialog.passenger.firstName.required">Name is required</span>
                      </md-field>
                      <md-field :class="getValidationClass('dialog.lastname')">
                          <label>Lastname</label>
                          <md-input v-model="dialog.passenger.lastName" ></md-input>
                          <span class="md-error" v-if="!$v.dialog.passenger.lastName.required">Lastname is required</span>
                      </md-field>
                      <md-field :class="getValidationClass('dialog.phone')">
                          <label>Phone</label>
                          <md-input v-model="dialog.passenger.phone" ></md-input>
                          <span class="md-error" v-if="!$v.dialog.passenger.phone.required">Name is required</span>
                      </md-field>
                      <md-field>
                          <label>Additional Phone</label>
                          <md-input v-model="dialog.passenger.additionalPhone" ></md-input>
                      </md-field>
                      <md-button v-if="dialog.existantPassenger != undefined" @click="updateExistantPassenger()" class="md-raised md-primary full-width-button">Update passenger</md-button>
                      <md-button v-else @click="addPassengers()" class="md-raised md-primary full-width-button">Add passenger</md-button>

                  </CCol>
              </CRow>
          </div>
      </md-dialog>

  </div>


  
</template>

<script>
import axiosApi from "./../../helpers/axios";
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'
export default {
  name: 'Client',
  mixins: [validationMixin],
  data() {
    return {
      isClient: null,
      isPassenger: null,
      userCheck: null,
      existingClient: null,
      checkingExistantClient:false,
      clientExsits: false,
      checkingUser: false,
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      isBusy: true,
      ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('clients'),
      checkingUser: false,
      dialog: {
          active: false,
          checkingUser: false,
          userCheck:null,
          passenger: {
              email: null,
              firstName:null,
              lastName:null,
              phone:null,
              additionalPhone:null,
              individual: false,
              checked: false
          }
      },
      client: null,
      passengers: [],
      removedPassengers: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    
    dialogDimensions () {
        return {
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
        }
    },
  },
  created () {
    this.obtainViewResources()
  },
  methods: {
    disabledField() {
       
       
        let disabled = false

        if(this.client.userCheck != null) {
            disabled = true
        } else if(this.userCheck != null) {
            disabled = true
        } else if (this.client.userCheck == null && this.userCheck == null) {
            disabled = false
        }


        return disabled
    },
    goToClient() {
        let name = this.existingClient.type === 'corporate' ?  this.existingClient.name : `${this.existingClient.firstName} ${this.existingClient.lastName}`
        this.$router.push({ name: 'Client', params: { name: name , id: this.existingClient.id } })
    },
    addPassengers() {
        if (this.validateNewPassenger()) {
            this.passengers.push(this.dialog.passenger);
            this.dialog = {
                active: false,
                checkingUser: false,
                userCheck: null,
                isPassenger: false,
                passenger: {
                    email: null,
                    firstName: null,
                    lastName: null,
                    phone: null,
                    additionalPhone: null,
                    individual: false,
                    checked: false
                }
            };
        } else {
            this.$v.dialog.passenger.$touch();
        }
    },
    async checkClientExistance() {
        console.log('checkClient existance')
        let th = this
        let data = {
            email:this.client.email
        }
        return new Promise((resolve, reject) => {
            
            firebase
            .firestore()
            .collection('users')
            .doc(this.user.data.uid)
            .collection('clients')
            .where('email','==', th.client.email)
            .get()
            .then(snapshot => {
                if(snapshot.size > 0) {
                    
                    snapshot.docs.forEach(doc => {
                        let dataToSave = doc.data()
                        dataToSave.id = doc.id
                        th.existingClient = dataToSave
                    })
                } else {
                    th.existingClient = null
                }
                resolve(snapshot.size)
            })


        })
    },
    checkUserExistance(email) {
        // return new Promise((resolve, reject) => {
        //     let data = {
        //         email: this.dialog.passenger.email
        //     };
        //     axiosApi.get(`users/check-existance/${data.email}`).then(response => {
        //         resolve(response);
        //     }).catch(e => {
        //         console.log(e);
        //         this.dialog.userCheck = null;
        //         this.dialog.passenger.checked = false;
        //         this.dialog.checkingUser = false;
        //         reject();
        //     });
        // });

        return new Promise((resolve, reject) => {
            let data = {
                email: email
            };
            axiosApi.get(`users/check-existance/${data.email}`).then(response => {
                resolve(response);
            }).catch(e => {
                console.log(e);
                this.isPassenger = null
                this.isClient = null
                this.userCheck = null
                this.client.userCheck = null
                this.checkingExistantClient = false;
                // this.dialog.userCheck = null;
                // this.dialog.passenger.checked = false;
                // this.dialog.checkingUser = false;
                reject();
            });
        });
    },
    editPassenger(index) {
        let actualPassenger = this.passengers[index]

        this.dialog =  {
            existantPassenger: index,
            active: false,
            checkingUser: false,
            userCheck:null,
            passenger: actualPassenger
        },

        this.dialog.active = true

    },
    async fireClientCheck() {
        // if(this.validateOnGoingClient()) {
        //     console.log('Validate on going client OK')
        //     await this.checkClientExistance() > 0 ? this.clientExsits = true : this.clientExsits =  false 
            
        // }


        this.checkingExistantClient = true;
        if(this.validateOnGoingClient()) {

            await this.checkClientExistance() > 0 ? this.clientExsits = true : this.clientExsits =  false 
            let response = await this.checkUserExistance(this.client.email);
            if(response) {

                this.isPassenger = response.data.isPassenger
                this.isClient = response.data.isClient
                this.userCheck = response.data.user;
                this.client.userCheck = response.data.user;
                
                if(this.isPassenger) {
                    this.client.firstName = this.userCheck.general.name
                    this.client.lastName = this.userCheck.general.lastname
                    this.client.phone = this.userCheck.general.phone
                }

                if(this.isClient) {
                    this.client.name = this.userCheck.general.companyName
                    this.client.phone = this.userCheck.general.phone
                }
                
            } else {
                this.isPassenger = null
                this.userCheck = null
                
            }
        }
        this.checkingExistantClient = false;
    },
    updateExistantPassenger() {

        this.passengers[this.dialog.existantPassenger] = this.dialog.passenger
        this.dialog.active = false
        this.dialog = {
            active: false,
            checkingUser: false,
            userCheck: null,
            isPassenger: false,
            passenger: {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
                individual: false,
                checked: false
            }
        };

    },
    passengerDialogClosed(){
        this.dialog = {
            active: false,
            checkingUser: false,
            userCheck: null,
            isPassenger: false,
            passenger: {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
                individual: false,
                checked: false
            }
        };

    },
    async fireUserCheck() {
        if (this.validateOnGoingEmail()) {
            this.dialog.checkingUser = true;
            let response = await this.checkUserExistance();
            
            this.dialog.isPassenger = response.data.isPassenger
            this.dialog.userCheck = response.data.user;
            if(this.dialog.userCheck && this.dialog.isPassenger) {

                this.dialog.passenger.firstName = this.dialog.userCheck.general.name;
                this.dialog.passenger.lastName = this.dialog.userCheck.general.lastname;
                this.dialog.passenger.phone = this.dialog.userCheck.general.phone;
                this.dialog.passenger.checked = true;
            }
            this.dialog.checkingUser = false;
        }
    },
    updateDefaultPassenger() {
        if(this.client.type === 'individual') {  
            let defaultPresent = false 
            for (let i = 0; i < this.passengers.length; i++) {
                if(this.passengers[i].default) {
                    defaultPresent = true
                    this.passengers[i].firstName = this.client.firstName
                    this.passengers[i].lastName = this.client.lastName
                    this.passengers[i].email = this.client.email
                    this.passengers[i].phone = this.client.phone
                    this.passengers[i].additionalPhone = this.client.additionalPhone
                    this.passengers[i].checked = this.client.name
                }
            }

            if(!defaultPresent) {
                let newDefault = {
                    firstName : this.client.firstName,
                    lastName : this.client.lastName,
                    email : this.client.email,
                    phone : this.client.phone,
                    additionalPhone : this.client.additionalPhone,
                    checked : this.client.name,
                    default: true
                }

                this.passengers.push(newDefault)
            }
        } else if(this.client.type === 'corporate') {
            for (let i = 0; i < this.passengers.length; i++) {
                if(this.passengers[i].default) {
                    this.passengers.splice(i,1)
                }
            }
        }
    },
    removePassenger(index) {
        this.removedPassengers.push(this.passengers.splice(index, 1)[0])
    },
    getClient() {
      let th = this;
      return new Promise((resolve, reject) => {
        
        firebase.firestore().collection('users').doc(this.user.data.uid).collection('clients').doc(this.$route.params.id).get()
          .then(function(doc) {
            if (doc.exists) {
                th.client = doc.data();
                th.isBusy = false
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

            resolve()
          }).catch(function(error) {
              console.log("Error getting document:", error);
              reject()
          });
      })
    },
    getPassengers() {
      let th = this ;
      this.passengers = []
      return new Promise(async (resolve, reject) => {
        
        let passengers = await firebase.firestore()
                                        .collection('users')
                                        .doc(th.user.data.uid)
                                        .collection('clients')
                                        .doc(th.$route.params.id)
                                        .collection('passengers')
                                        .get()


        passengers.forEach(doc => {
          let dataToSave = doc.data()
          dataToSave.id = doc.id
          th.passengers.push(dataToSave)
        })
        resolve()
      })
    },
    getCompanyProfiles() {
        let th = this;
        return new Promise((resolve, reject) => {
            if (th.user.data.activeRole == 'company-admin') {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                    th.companyProfiles = [];
                    querySnapshot.forEach((doc) => {
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id;
                        th.companyProfiles.push(dataToSave);
                    });
                    resolve();
                });
            } else {
                th.companyProfiles.push(th.reservation.companyProfile);
                resolve();
            }
        });
    },
    async obtainViewResources() {
        this.isBusy = true;
        await this.getCompanyProfiles();
        await this.getClient();
        await this.getPassengers()
        this.isBusy = false;
    },
    fireUpdate() {
        if(this.validate()) {
            console.log('Valid')
            this.update()
        } else {
            console.log('Invalid')
            this.$v.client.$touch()
            this.$v.passengers.$touch()
        }

    },


    update() {
        this.isBusy = true
        let passengers = this.passengers;
        let client = this.client

       

        let data = {
            client: client,
            passengers,
            uid: this.user.data.uid,
            removedPassengers: this.removedPassengers,
            clientId: this.$route.params.id
        };

        let th = this;
        return new Promise((resolve, reject) => {
            axiosApi.patch('/clients', data).then(response => {
                th.isBusy = false;
                th.$router.push({name: `Clients List`})
                //th.$router.push({path: `/app/clients`})
            }).catch(e => {
                th.isBusy = false;
            });
        });
    },
    destroy() {
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('clients').doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({name: `Clients List`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    },
    validate(){
        console.log('Validate function fired')
        let  passengers, name,lastname, email, phone;
        passengers = this.$v.passengers.$invalid;
        name = this.$v.client.firstName.$invalid;
        lastname = this.$v.client.lastName.$invalid;
        email = this.$v.client.email.$invalid;
        phone = this.$v.client.phone.$invalid;

       

        if(!email && this.clientExsits) {
            console.log('invalid email 1')
            email = true
        }

        if(this.userCheck) {
            if(!this.isPassenger && this.client.type === 'individual') {
                console.log('invalid email 2')
                email = true
            }

            if(!this.isClient && this.client.type === 'corporate') {
                console.log('invalid email 3')
                email = true
            }
        }
        console.log(passengers,name,lastname,email,phone)

        return  !passengers && !name && !lastname && !email && !phone ? true : false;
    },
    getValidationClass(fieldName) {
        let field = null;
        if (fieldName == 'dialog.email' || fieldName == 'dialog.name' || fieldName == 'dialog.lastname' || fieldName == 'dialog.phone') {
            let fieldSplit = fieldName.split('dialog.')[1];
            field = this.$v.dialog.passenger[fieldSplit];
        } else if (fieldName == 'individual.email' || fieldName == 'individual.firstName' || fieldName == 'individual.lastName' || fieldName == 'individual.phone') {
            let fieldSplit = fieldName.split('individual.')[1];
            field = this.$v.passengers.$each[0][fieldSplit];
        } else if (fieldName == 'client.email' || fieldName == 'client.firstName' || fieldName == 'client.lastName' || fieldName == 'client.phone' || fieldName == 'client.name') {

            let fieldSplit = fieldName.split('client.')[1];

            let clientExsits = {
                $invalid: false,
                $dirty: false
            }

            if(fieldName == 'client.email') {
                clientExsits.$invalid = this.clientExsits
                clientExsits.$dirty = true
            }

            let specialValidation = false
            if(fieldName == 'client.email' && this.userCheck) {

                if(this.client.type === 'individual' && !this.isPassenger){
                    clientExsits.$invalid = true
                    clientExsits.$dirty = true
                    specialValidation = true

                } else if(this.client.type === 'corporate' && !this.isClient) {
                    clientExsits.$invalid = true
                    clientExsits.$dirty = true
                    specialValidation = true
                }
            }

            field = this.clientExsits || specialValidation ? clientExsits : this.$v.client[fieldSplit];
        } else {
            field = this.$v.client[fieldName];
        }
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty,
                'mb-5': fieldName === 'client.email' && field.$invalid && ( this.userCheck  || this.clientExsits)
            };
        }
    },
    validateCorporate() {
        let  passengers, name, email, phone;
        passengers = this.$v.passengers.$invalid;
        name = this.$v.client.name.$invalid;
        email = this.$v.client.email.$invalid;
        phone = this.$v.client.phone.$invalid;
        return  !passengers && !name && !email && !phone ? true : false;
    },
    validateIndividual() {
        let email, name, lastname, phone, companyProfile;
        email = this.$v.passengers.$each[0].email.$invalid;
        name = this.$v.passengers.$each[0].name.$invalid;
        lastname = this.$v.passengers.$each[0].lastname.$invalid;
        phone = this.$v.passengers.$each[0].phone.$invalid;
        companyProfile = this.$v.client.companyProfile.$invalid;
        return !email && !name && !lastname && !phone && !companyProfile ? true : false;
    },
    validateNewPassenger() {
        let email, name, lastname, phone;
        email = this.$v.dialog.passenger.email.$invalid;
        name = this.$v.dialog.passenger.firstName.$invalid;
        lastname = this.$v.dialog.passenger.lastName.$invalid;
        phone = this.$v.dialog.passenger.phone.$invalid;
        return !email && !name && !lastname && !phone ? true : false;
    },
    validateOnGoingEmail() {
        let email = this.$v.dialog.passenger.email.$invalid;
        console.log(email);
        return !email ? true : false;
    },
    validateOnGoingClient() {
        let email = this.$v.client.email.$invalid
        return !email ? true : false 
    },
  },
  validations: {
       
        client: {
            email: {
                email,
                required
            },
            firstName: {
                required: requiredIf(function(val) {
                    return val.type  === 'individual' && !this.userCheck
                })
            },
            lastName: {
                required: requiredIf(function(val) {
                    return val.type  === 'individual' && !this.userCheck
                })
            },
            name: {
                required: requiredIf(function(val) {
                    return val.type  === 'corporate'
                })
            },
            phone: {
                required: requiredIf(function(val) {
                    return this.client.userCheck == null
                })
            },
            companyProfile: {
                required
            }
            
        },
        passengers: {
            required
        },
        //  passengers: {
        //     $each: {
        //         email: {
        //             email,
        //             required
        //         },
        //         firstName:{
        //             required
        //         },
        //         lastName: {
        //             required
        //         },
        //         phone: {
        //             required
        //         },
                
        //     }
        // },
        dialog: {
           passenger: {
               email: {
                   email,
                   required
               },
               firstName:{
                   required: requiredIf(function(val){
                       return !val.checked 
                   })
               },
               lastName: {
                   required: requiredIf(function(val){
                       return !val.checked 
                   })
               },
               phone: {
                   required: requiredIf(function(val){
                       return !val.checked 
                   })
               },
               
           }
       }
   },
   watch: {
        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        },
        'client': {
            handler(val,oldVal) {
               
                if(oldVal != null) {
                    this.updateDefaultPassenger()
                    
                }
                //this.updateDefaultPassenger()
            },
            deep: true

        },
        'client.type': {
            handler(val,oldVal) {
             
                console.log('ii client watcher', val)
                console.log('ii client watcher old', oldVal)
                if(oldVal != null && val != oldVal) {
                    this.fireClientCheck()
                }
                   
            }
        }
        

    }
  
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

